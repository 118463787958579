import * as React from 'react';
import { hotjar } from 'react-hotjar';
import CssBaseline from '@mui/material/CssBaseline';
import AppContextProvider from '@crema/context/AppContextProvider';
import AppThemeProvider from '@crema/context/AppThemeProvider';
import AppLocaleProvider from '@crema/context/AppLocaleProvider';
import AppAuthProvider from '../src/core/AppAuthProvider';
import AuthRoutes from '@crema/components/AuthRoutes';

import '../public/styles/vendors/index.css';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

import AppPageMeta from '@crema/components/AppPageMeta';
import InfoViewContextProvider from '@crema/context/InfoViewContextProvider';
import createEmotionCache from '../createEmotionCache';
import { AppProps } from 'next/app';
import { ErrorInfo, useEffect } from 'react';
import SearchContextProvider from '@src/modules/apps/context/SearchContextProvider';
import Bugsnag from '../libs/services/bugsnag';
import { ensureUniqueKey, getGoogleConsentFromRaw } from '@crema/helpers';
import Head from 'next/head';
import { OnErrorCallback } from '@bugsnag/core';
import { AppCacheProvider } from '@mui/material-nextjs/v15-pagesRouter';

import { googleTagManagerId } from '@crema/constants/AppConst';
import Script from 'next/script';
import { useCookies } from 'react-cookie';
import { CAT_ANALYTICS } from '../libs/components/src/lib/AppCookieConsent/CookieConsentConfig';
import { hotjarInit } from '../libs/modules/src/lib/analytics/Hotjar';
import CookieConsentComponent from 'libs/components/src/lib/AppCookieConsent/CookieConsent';
import * as CookieConsent from 'vanilla-cookieconsent';
import AnalyticsUserProvider from '../libs/modules/src/lib/analytics/Google';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const ErrorView = (params: { error: Error; info: ErrorInfo; clearError: () => void }) => {
  console.error('ErrorView: ', params);

  return (
    <div>
      <p>Inform users of an error in the component tree.</p>
    </div>
  );
};

type Props = {
  children?: React.ReactNode;
  onError?: OnErrorCallback;
  FallbackComponent?: React.ComponentType<{
    error: Error;
    info: React.ErrorInfo;
    clearError: () => void;
  }>;
};

const FallbackErrorBoundary = ({ children }: Props) => {
  return <>{children}</>;
};

const ErrorBoundary = Bugsnag.isStarted()
  ? Bugsnag.getPlugin('react')?.createErrorBoundary(React) || FallbackErrorBoundary
  : FallbackErrorBoundary;

export default function MyApp(props: AppProps) {
  const { Component, pageProps } = props;
  ensureUniqueKey();

  const [cookies] = useCookies(['cc_cookie']);
  const consentCookie = getGoogleConsentFromRaw(cookies.cc_cookie);

  useEffect(() => {
    // running on mount
    if (hotjar.initialized()) {
      return;
    }

    if (!CookieConsent.validConsent() || !CookieConsent.acceptedCategory(CAT_ANALYTICS)) {
      // console.log('Skipping hotjar for now. No consent.');
      return;
    }

    hotjarInit();
  }, []);

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorView}>
        <AppCacheProvider emotionCache={clientSideEmotionCache} {...props}>
          {googleTagManagerId && (
            <Script id='google-analytics' strategy='afterInteractive'>
              {process.env.NODE_ENV === 'development' ? `console.log('[ga] Config pushed!');` : null}
              {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', '${googleTagManagerId}');

            const consentCookie = ${JSON.stringify(consentCookie)};
            const hasConsentData = ${cookies.cc_cookie ? 'true' : 'false'};

            if (Object.keys(consentCookie).length === 0 || !hasConsentData) {
              gtag('consent', 'default', {
                  'ad_personalization': 'denied',
                  'ad_user_data': 'denied',
                  'ad_storage': 'denied',
                  'analytics_storage': 'denied'
              });
            } else {
              gtag('consent', 'update', consentCookie);
            }
            `}
            </Script>
          )}

          {/*{googleTagManagerId && (
              <Script id='google-analytics' strategy='afterInteractive'>
                {process.env.NODE_ENV === 'development' ? `console.log('[ga] Config pushed!');` : null}
                {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${googleTagManagerId}');
            `}
              </Script>
          )}*/}
          <AppPageMeta {...(pageProps.meta || {})} />
          <Head>
            <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=5.0' />
            <noscript>
              <style>
                {`.AuthWrapper-container { max-width: 400px !important;} .MuiCheckbox-root .MuiSvgIcon-root {display: none;}
                /* Other styles specifically for no-JS scenario */
              `}
              </style>
            </noscript>
          </Head>
          {/*<GoogleTagManager gtmId={googleTagManagerId} />*/}
          {/*<GoogleAnalytics gaId={googleTagManagerId} />*/}

          <AppContextProvider>
            <AppThemeProvider>
              <AppLocaleProvider>
                <InfoViewContextProvider>
                  <AppAuthProvider>
                    <AuthRoutes>
                      <CssBaseline />
                      <SearchContextProvider>
                        <Component {...pageProps} />
                      </SearchContextProvider>
                    </AuthRoutes>
                    <AnalyticsUserProvider />
                  </AppAuthProvider>
                </InfoViewContextProvider>
              </AppLocaleProvider>
            </AppThemeProvider>
          </AppContextProvider>
          <CookieConsentComponent type={'mount'} />
        </AppCacheProvider>
      </ErrorBoundary>
    </>
  );
}
