import { authRole } from '@crema/constants/AppConst';
import { AuthUserType, UserRoles } from '@crema/models/AuthUser';
import { BlogPageAuthorType } from '@crema/models/Blog';
import { v4 as uuidv4 } from 'uuid';
import { readFromStorage, writeToStorage } from './StorageHelper';
import defaultConfig from '@crema/constants/defaultConfig';
import Cookies from 'universal-cookie';
import { GuideType, JobRoleType } from '@crema/models/apps/Guide';
import {
  CAT_ANALYTICS,
  CAT_FUNCTIONALITY,
  SERVICE_AD_PERSONALIZATION,
  SERVICE_AD_STORAGE,
  SERVICE_AD_USER_DATA,
  SERVICE_ANALYTICS_STORAGE,
  SERVICE_FUNCTIONALITY_STORAGE,
} from '../../components/src/lib/AppCookieConsent/CookieConsentConfig';
import { CookieValue } from 'vanilla-cookieconsent';
import { isEmpty } from 'lodash';

enum ApiUserRole {
  user = 'user',
  admin = 'admin',
  owner = 'owner',
  guest = 'guest',
}

type UserMappingType = {
  user: string;
  admin: string;
  guest: string;
  owner: string;
};

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
export const getUserFromJwtAuth = (user: any): AuthUserType => {
  const mapping: UserMappingType = {
    user: authRole.User,
    admin: authRole.Admin,
    owner: authRole.Owner,
    guest: authRole.Guest,
  };

  if (user) {
    const userRole: UserRoles[] = [UserRoles.GUEST];

    if (user.role) {
      const userRoles: ApiUserRole[] = Array.isArray(user.role) ? user.role : [user.role];

      userRoles.forEach((role) => {
        if (role in mapping) {
          userRole.push(<UserRoles>mapping[role]);
        } else {
          console.error('Invalid user role', user.role);
        }
      });
    }

    const returnUser: AuthUserType = {
      id: user.id,
      team_id: user.team_id,
      uid: user._id,
      name: user.name,
      email: user.email,
      avatar: user.avatar,
      locale: user.locale,
      role: userRole,
      email_verified: !!user.email_verified_at,
      ...(user.hash_validation ? { hash_validation: user.hash_validation } : {}),
      count_guides: user.count_guides,
      count_job_roles: user.count_job_roles,
    };

    return returnUser;
  }

  return user;
};

export const isUserAuthor = (user: AuthUserType | undefined, author: BlogPageAuthorType | undefined) => {
  if (!user || !author) {
    return false;
  }

  return user && author && user.role?.indexOf('admin') !== -1 && author.author_id === user.id;
};

export const userHasRole = (user: AuthUserType, role: UserRoles) => {
  return user.role.includes(role);
};

export const userHasAnyRole = (user: AuthUserType, roles: UserRoles[]) => {
  return roles.some((role) => user.role.includes(role));
};

export const userCanEditJobRole = (user: AuthUserType, object: JobRoleType) => {
  return user.id === object.author?.id;
};

export const userCanEditGuide = (user: AuthUserType, object: GuideType) => {
  return user.id === object.author?.id;
};

export const userCanDeleteGuide = (user: AuthUserType, object: GuideType) => {
  return user.id === object.author?.id;
};

const universalCookies = new Cookies(null, {
  sameSite: 'strict',
  path: '/',
});
export const apiToken = universalCookies.get('auth-token');

export const ensureUniqueKey = () => {
  let qToken = universalCookies.get('auth-q');
  if (!qToken) {
    qToken = uuidv4();

    universalCookies.set('auth-q', qToken, {
      maxAge: 365 * 24 * 60 * 60,
    });
  }

  return qToken;
};

export const getBrowserLocale = () => {
  return readFromStorage('locale', defaultConfig.locale.locale, 'string', 'session');
};

export const getBrowserLocaleStrict = () => {
  return readFromStorage('locale', '', 'string', 'session');
};

export const setBrowserLocale = (locale: string) => {
  writeToStorage('locale', locale, 'session');
};

export const hasBrowserLocale = () => {
  return !isEmpty(readFromStorage('locale', '', 'string', 'session'));
};

export const getGoogleConsentFromRaw = (coookieData?: CookieValue) => {
  return ((coookieData ?? {}).categories ?? []).reduce(
    (acc: { [key: string]: 'granted' | 'denied' }, c: string) => {
      if (c === CAT_ANALYTICS) {
        acc[SERVICE_ANALYTICS_STORAGE] = 'granted';
      } else if (c === CAT_FUNCTIONALITY) {
        acc[SERVICE_FUNCTIONALITY_STORAGE] = 'granted';
      } else if (c === 'targeting') {
        acc[SERVICE_AD_USER_DATA] = 'granted';
        acc[SERVICE_AD_PERSONALIZATION] = 'granted';
        acc[SERVICE_AD_STORAGE] = 'granted';
      }

      return acc;
    },
    {
      [SERVICE_AD_USER_DATA]: 'denied',
      [SERVICE_AD_PERSONALIZATION]: 'denied',
      [SERVICE_AD_STORAGE]: 'denied',
      [SERVICE_ANALYTICS_STORAGE]: 'denied',
      [SERVICE_FUNCTIONALITY_STORAGE]: 'denied',
    },
  );
};
