export type AuthUserType = {
  id: number;
  team_id: number;
  uid?: string;
  name?: string;
  position?: string;
  email?: string;
  avatar?: string;
  token?: string;
  role: string[] | string;
  email_verified?: boolean;
  force_validation?: boolean;
  hash_validation?: string;
  count_guides: number;
  count_job_roles: number;
  locale: string;
};

export enum UserRoles {
  ADMIN = 'admin',
  USER = 'user',
  OWNER = 'owner',
  GUEST = 'guest',
}

export type UserType = {
  id: number;
  team_id: number;
  name: string;
  business_name: string;
  position: string;
  short_description: string;
  email: string;
  avatar: string;
  email_verified: boolean;
  locale: string;
};

export type TeamUserType = {
  id: number;
  team_id: number;
  name: string;
  business_name: string;
  position: string;
  short_description: string;
  avatar: string;
};

export type ApiResourceType<T> = {
  data: T;
};

export const UserInitialData: UserType = {
  id: 0,
  team_id: 0,
  name: '',
  business_name: '',
  email: '',
  avatar: '',
  position: '',
  short_description: '',
  email_verified: false,
  locale: '',
};
