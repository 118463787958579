import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import defaultConfig from '@crema/constants/defaultConfig';
import { useThemeActionsContext, useThemeContext } from './ThemeContextProvider';
import { LanguageProps } from '@crema/models/Apps';
import { getBrowserLocaleStrict, setBrowserLocale } from '@crema/helpers';
import { getDataForLocale } from '../../../components/src/lib/AppLngSwitcher/data';
import { useRouter } from 'next/router';
import { isEmpty } from 'lodash';

export interface LocaleContextData {
  locale: LanguageProps;
}

export interface LocaleActionsData {
  updateLocale: (locale: LanguageProps) => void;
}

export const LocaleContext = createContext<LocaleContextData>({
  locale: defaultConfig.locale,
});
export const LocaleActionsContext = createContext<LocaleActionsData>({
  updateLocale: () => {},
});

export const useLocaleContext = () => useContext(LocaleContext);

export const useLocaleActionsContext = () => useContext(LocaleActionsContext);

interface LocaleContextProviderProps {
  children: ReactNode;
  forceLocale?: string;
}

const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
  console.log('changed handleRouteChange: ', url, 'shallow', shallow);
};

const LocaleContextProvider: React.FC<LocaleContextProviderProps> = ({ children, forceLocale = 'en' }) => {
  const router = useRouter();

  const { locale: nextLocale, defaultLocale, pathname, asPath, query } = router;
  const defLocale = nextLocale ? nextLocale : forceLocale || defaultLocale || 'en'; // || defaultConfig.locale.locale;

  // console.log(`render calculated locale: asked ${defLocale} got:`, getDataForLocale(defLocale));

  const [locale, updateLocale] = useState<LanguageProps>(getDataForLocale(defLocale));
  const { theme } = useThemeContext();
  const { updateTheme } = useThemeActionsContext();

  // useEffect(() => {
  //   console.log('locale useState changed: ', locale);
  // }, [locale]);

  // useEffect(() => {
  //   router.events.on('routeChangeComplete', handleRouteChange);
  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange);
  //   };
  // }, [router.events]);

  // useEffect(() => {
  //   console.log('one locale changed state: ', locale.locale, {
  //     using: locale.locale,
  //     forceLocale: forceLocale,
  //     next_from_url: nextLocale,
  //     fallback: defLocale,
  //   });
  // }, [nextLocale, defLocale, locale.locale]);

  useEffect(() => {
    // respect url
    // if (nextLocale)
    //   if (getBrowserLocaleStrict() && getBrowserLocaleStrict() !== nextLocale) {
    //     // if (nextLocale && !isEmpty(nextLocale)) {
    //     //   setBrowserLocale(nextLocale);
    //     // }
    //     // router.push({ pathname, query }, asPath, {
    //     //   locale: nextLocale,
    //     // });
    //   } else if (isEmpty(getBrowserLocaleStrict()) && nextLocale && nextLocale !== defaultLocale) {
    //     // setBrowserLocale(nextLocale);
    //   }

    // console.log('defLocale, nextLocale changed:', {
    //   defLocale: defLocale,
    //   nextLocale: nextLocale,
    // });

    if (locale.locale !== nextLocale) {
      // console.log('different language url with system, changing to:', defLocale);

      router.push({ pathname, query }, asPath, {
        locale: defLocale,
      });

      updateLocale(getDataForLocale(defLocale));
      setBrowserLocale(defLocale);
    }
  }, [defLocale, nextLocale, locale.locale]);

  useEffect(() => {
    updateTheme({
      ...theme,
    });
  }, [locale, theme, updateTheme]);

  // console.log('locale render: ', locale);

  return (
    <LocaleContext.Provider
      value={{
        locale,
      }}
    >
      <LocaleActionsContext.Provider
        value={{
          updateLocale,
        }}
      >
        {children}
      </LocaleActionsContext.Provider>
    </LocaleContext.Provider>
  );
};

export default LocaleContextProvider;
