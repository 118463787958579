import { CookieConsentConfig } from 'vanilla-cookieconsent';
import { appDomain, appParentDomain, googleTagManagerId, hotjarHjid } from '@crema/constants/AppConst';
import * as CookieConsent from 'vanilla-cookieconsent';
import { trackEvent } from '../../../../helpers/src/Analytics';
import { hotjarClearCookies, hotjarInit } from '../../../../modules/src/lib/analytics/Hotjar';

export const CAT_NECESSARY = 'necessary';
export const CAT_ANALYTICS = 'analytics';
export const CAT_ADVERTISEMENT = 'advertisement';
export const CAT_FUNCTIONALITY = 'functionality';
export const CAT_SECURITY = 'security';

export const SERVICE_AD_STORAGE = 'ad_storage';
export const SERVICE_AD_USER_DATA = 'ad_user_data';
export const SERVICE_AD_PERSONALIZATION = 'ad_personalization';
export const SERVICE_ANALYTICS_STORAGE = 'analytics_storage';
export const SERVICE_FUNCTIONALITY_STORAGE = 'functionality_storage';
export const SERVICE_PERSONALIZATION_STORAGE = 'personalization_storage';
export const SERVICE_SECURITY_STORAGE = 'security_storage';

const pluginConfig: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: 'box',
      position: 'bottom right',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      position: 'right',
      equalWeightButtons: true,
      flipButtons: true,
    },
  },

  onFirstConsent: function (cookie) {
    // console.log('CookieConsent onFirstAction fired', cookie, this);

    // console.log(
    //   'CookieConsent onFirstConsent:',
    //   CookieConsent.acceptedService(SERVICE_ANALYTICS_STORAGE, CAT_ANALYTICS),
    // );

    // if (CookieConsent.acceptedService(SE, CAT_ANALYTICS)) {
    //
    // }

    if (cookie.cookie.categories.includes(CAT_NECESSARY) && cookie.cookie.categories.length === 1) {
      trackEvent('cookie_consent', 'first_consent', 'accept_necessary', 1);
    } else if (cookie.cookie.categories.length === 4) {
      trackEvent('cookie_consent', 'first_consent', 'accept_all', 1);
    }
  },

  onConsent: function ({ cookie }) {
    // console.log('CookieConsent onConsent fired ...', cookie);

    const consentData: { [key: string]: string } = {};
    if (cookie.categories.includes(CAT_ANALYTICS)) {
      consentData[SERVICE_ANALYTICS_STORAGE] = 'granted';
    } else {
      consentData[SERVICE_ANALYTICS_STORAGE] = 'denied';
    }

    if (cookie.categories.includes('targeting')) {
      consentData['ad_user_data'] = 'granted';
      consentData['ad_personalization'] = 'granted';
      consentData['ad_storage'] = 'granted';
    } else {
      consentData['ad_user_data'] = 'denied';
      consentData['ad_personalization'] = 'denied';
      consentData['ad_storage'] = 'denied';
    }

    'gtag' in window && typeof window.gtag === 'function' && window.gtag('consent', 'update', consentData);

    if (cookie.categories.includes(CAT_ANALYTICS)) {
      hotjarInit();
    } else {
      // hotjarClearCookies(cookie, CookieConsent.getConfig(), CookieConsent.eraseCookies);
    }

    //console.log('CookieConsent onConsent:', CookieConsent.acceptedService(SERVICE_ANALYTICS_STORAGE, CAT_ANALYTICS));

    //For Testing
    //console.log('Cookie Consent: ', consentData);
  },

  onChange: function ({ changedCategories, cookie }) {
    // console.log('CookieConsent onChange fired ...', { changedCategories, cookie });

    const consentData: { [key: string]: string } = {};
    if (cookie.categories.includes(CAT_ANALYTICS)) {
      consentData[SERVICE_ANALYTICS_STORAGE] = 'granted';
    } else {
      consentData[SERVICE_ANALYTICS_STORAGE] = 'denied';
    }

    if (cookie.categories.includes('targeting')) {
      consentData['ad_user_data'] = 'granted';
      consentData['ad_personalization'] = 'granted';
      consentData['ad_storage'] = 'granted';
    } else {
      consentData['ad_user_data'] = 'denied';
      consentData['ad_personalization'] = 'denied';
      consentData['ad_storage'] = 'denied';
    }

    'gtag' in window && typeof window.gtag === 'function' && window.gtag('consent', 'update', consentData);

    // changed and granted
    if (changedCategories.includes(CAT_ANALYTICS)) {
      if (cookie.categories.includes(CAT_ANALYTICS)) {
        // console.log('should load hotjar');
        hotjarInit();
      } else {
        //console.log('Should unload');
        //console.log('Unload manually: window.location.reload();');
        window.location.reload();
      }
    }

    //console.log('CookieConsent onChange:', CookieConsent.acceptedService(SERVICE_ANALYTICS_STORAGE, CAT_ANALYTICS));
    // console.log('Cookie Consent: ', consentData);
  },

  categories: {
    [CAT_NECESSARY]: {
      readOnly: true,
      enabled: true,
    },
    [CAT_FUNCTIONALITY]: {
      autoClear: {
        cookies: [
          {
            name: 'locale',
          },
        ],
        // reloadPage: true,
      },
    },
    [CAT_ANALYTICS]: {
      autoClear: {
        cookies: [
          {
            name: googleTagManagerId.replace(/G-/, '_ga_'),
          },
          {
            name: '_ga',
          },
          {
            name: '_hjSessionUser_' + hotjarHjid,
            domain: appParentDomain,
          },
          {
            name: '_hjSession_' + hotjarHjid,
            domain: appParentDomain,
            path: '/',
          },
          {
            name: '_hjUserAttributesHash',
            path: '/',
            // domain: appParentDomain,
          },
          {
            name: '_hjHasCachedUserAttributes',
            path: '/',
            domain: appDomain,
            // domain: appParentDomain,
          },
        ],
        // reloadPage: true,
      },
      services: {
        [SERVICE_ANALYTICS_STORAGE]: {
          label: 'Enables storage (such as cookies) related to analytics e.g. visit duration.',
        },
      },
    },
    targeting: {},
  },

  language: {
    default: 'en',
    autoDetect: 'document',
    translations: {
      en: {
        consentModal: {
          title: "Hello traveller, it's cookie time!",
          description:
            'Our website uses tracking cookies to understand how you interact with it. The tracking will be enabled only if you accept explicitly. <a href="#privacy-policy" data-cc="show-preferencesModal" class="cc__link">Manage preferences</a>',
          acceptAllBtn: 'Accept all',
          // acceptNecessaryBtn: 'Only necessary',
          showPreferencesBtn: 'Manage preferences',
          //closeIconLabel: 'Close',
          footer: `
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/tos">Terms of service</a>
          `,
        },
        preferencesModal: {
          title: 'Cookie preferences',
          savePreferencesBtn: 'Save preferences',
          acceptAllBtn: 'Accept all',
          // acceptNecessaryBtn: 'Only necessary',

          closeIconLabel: 'Close',
          sections: [
            {
              title: 'Cookie Usage',
              description:
                'When you visit a website, it may store or retrieve information on your browser using cookies. These help the site function properly and enhance your experience. While they don’t usually identify you directly, they can personalize your browsing. You can manage your cookie preferences below, but disabling some may affect site functionality. For more details related to cookies and other sensitive data, please read the full <a href="/privacy-policy" class="cc__link">privacy policy</a>.',
            },
            {
              title: 'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
              description:
                'These cookies are essential for the website to function and cannot be disabled. They are set in response to actions you take, like setting privacy preferences, logging in, or filling out forms. While you can block them in your browser, some site features may not work. These cookies do not store personal information.',
              linkedCategory: CAT_NECESSARY,
              cookieTable: {
                headers: {
                  name: 'Name',
                  domain: 'Service',
                  description: 'Description',
                  expiration: 'Expiration',
                },
                body: [
                  {
                    name: 'auth-token',
                    domain: appDomain,
                    description: 'User authentication token. It is used to authenticate the user.',
                    expiration: 'Up to 30 days',
                  },
                  {
                    name: 'auth-id',
                    domain: appDomain,
                    description: 'User',
                    expiration: 'Up to 30 days',
                  },
                  {
                    name: 'auth-expires-at',
                    domain: appDomain,
                    description: 'It stores the user authentication expiration date. ',
                    expiration: 'Up to 30 days',
                  },
                  {
                    name: 'cf_clearance',
                    domain: appDomain,
                    description:
                      'Clearance Cookie stores the proof of challenge passed. It is used to no longer issue a challenge if present. It is required to reach an origin server.',
                    expiration: 'Expires after 12 days',
                  },
                  {
                    name: 'cc_cookie',
                    domain: appDomain,
                    description:
                      'This cookie is set by the cookie compliance solution. It stores information about the categories of cookies the site uses and whether visitors have given or withdrawn consent for the use of each category. This enables site owners to prevent cookies in each category from being set in the users browser, when consent is not given. The cookie has a normal lifespan of 6 months, so that returning visitors to the site will have their preferences remembered. It contains no information that can identify the site visitor.',
                    expiration: 'Expires after 6 months',
                  },
                ],
              },
            },
            {
              title: 'Functionality Cookies',
              description:
                'Functionality cookies help us remember your preferences to make your experience more personalized. For example, they allow us to save your preferred language, so you don’t have to set it every time you visit. These cookies ensure the site works the way you like it.',
              linkedCategory: CAT_FUNCTIONALITY,
              cookieTable: {
                headers: {
                  name: 'Name',
                  domain: 'Service',
                  description: 'Description',
                  expiration: 'Expiration',
                },
                body: [
                  {
                    name: 'auth-q',
                    domain: appDomain,
                    description: 'Used to distinguish users and sessions for the purpose of error reporting.',
                    expiration: 'Expires after 1 year',
                  },
                  {
                    name: 'locale',
                    domain: appDomain,
                    description: 'Locale',
                    expiration: 'Expires after 1 year',
                  },
                ],
              },
              //auth-q
            },
            {
              title: 'Performance and Analytics cookies',
              description:
                'These cookies help us measure and improve site performance by tracking visits and traffic sources. They show which pages are popular and how visitors navigate the site.',
              linkedCategory: CAT_ANALYTICS,
              cookieTable: {
                headers: {
                  name: 'Name',
                  domain: 'Service',
                  description: 'Description',
                  expiration: 'Expiration',
                },
                body: [
                  {
                    name: '_ga',
                    domain: appParentDomain,
                    description: 'Used to distinguish users.',
                    expiration: 'Expires after 2 years',
                  },
                  {
                    name: googleTagManagerId.replace(/G-/, '_ga_'),
                    domain: appParentDomain,
                    description: 'Used to persist session state.',
                    expiration: 'Expires after 2 years',
                  },
                ],
              },
            },
            {
              title: 'Targeting Cookies',
              description:
                'Targeting cookies help us show you ads that match your interests when you visit other websites. These cookies track your browsing habits to make advertisements more relevant to you.',
              linkedCategory: 'targeting',
            },
          ],
        },
      },
      ro: {
        consentModal: {
          title: 'Salut călătorule, e timpul pentru cookie-uri!',
          description:
            'Site-ul nostru folosește cookie-uri de urmărire pentru a înțelege cum interacționezi cu el. Urmărirea va fi activată doar dacă accepți explicit. <a href="#privacy-policy" data-cc="show-preferencesModal" class="cc__link">Gestionați preferințele</a>',
          acceptAllBtn: 'Acceptă toate',
          acceptNecessaryBtn: 'Doar necesare',
          showPreferencesBtn: 'Gestionați preferințele',
          //closeIconLabel: 'Închide',
          footer: '<a href="/privacy-policy">Politica de confidențialitate</a>  <a href="/tos">Termeni și condiții</a>',
        },
        preferencesModal: {
          title: 'Preferințe cookie',
          savePreferencesBtn: 'Salvează preferințele',
          acceptAllBtn: 'Acceptă toate',
          // acceptNecessaryBtn: 'Doar necesare',

          closeIconLabel: 'Închide',
          sections: [
            {
              title: 'Utilizarea cookie-urilor',
              description:
                'Când vizitezi un site web, acesta poate stoca sau accesa informații în browserul tău folosind cookie-uri. Acestea ajută site-ul să funcționeze corect și îți îmbunătățesc experiența. Deși, de obicei, nu te identifică direct, pot personaliza navigarea ta. Poți gestiona preferințele tale mai jos, dar dezactivarea unora poate afecta funcționalitatea site-ului. Pentru mai multe detalii legate de cookie-uri și alte date sensibile, te rugăm să citești <a href="/privacy-policy" class="cc__link">politica de confidențialitate</a>.',
            },
            {
              title: 'Cookie-uri strict necesare <span class="pm__badge">Mereu activate</span>',
              description:
                'Aceste cookie-uri sunt esențiale pentru funcționarea site-ului și nu pot fi dezactivate. Ele sunt setate ca răspuns la acțiunile tale, cum ar fi setarea preferințelor de confidențialitate, autentificarea sau completarea formularelor. Deși le poți bloca în browser, unele funcționalități ale site-ului pot să nu mai funcționeze. Aceste cookie-uri nu stochează informații personale.',
              linkedCategory: CAT_NECESSARY,
              cookieTable: {
                headers: {
                  name: 'Nume',
                  domain: 'Serviciu',
                  description: 'Descriere',
                  expiration: 'Expirare',
                },
                body: [
                  {
                    name: 'auth-token',
                    domain: appDomain,
                    description: 'Token de autentificare al utilizatorului. Este folosit pentru autentificare.',
                    expiration: 'Până la 30 de zile',
                  },
                  {
                    name: 'auth-id',
                    domain: appDomain,
                    description: 'Utilizator',
                    expiration: 'Până la 30 de zile',
                  },
                  {
                    name: 'auth-expires-at',
                    domain: appDomain,
                    description: 'Stochează data de expirare a autentificării utilizatorului.',
                    expiration: 'Până la 30 de zile',
                  },
                  {
                    name: 'cf_clearance',
                    domain: appDomain,
                    description:
                      'Cookie de verificare ce stochează dovada unui test trecut. Este folosit pentru a nu mai solicita testul dacă este deja prezent. Este necesar pentru accesul la serverul sursă.',
                    expiration: 'Expiră după 12 zile',
                  },
                  {
                    name: 'cc_cookie',
                    domain: appDomain,
                    description:
                      'Acest cookie este setat de soluția de conformitate cu cookie-urile. Stochează informații despre categoriile de cookie-uri utilizate de site și dacă vizitatorii și-au dat sau retras consimțământul pentru utilizarea fiecărei categorii. Acesta permite proprietarilor site-ului să împiedice setarea cookie-urilor în fiecare categorie dacă nu s-a dat consimțământul. Cookie-ul are o durată de viață de 6 luni pentru a reține preferințele utilizatorilor care revin. Nu conține informații care pot identifica vizitatorul.',
                    expiration: 'Expiră după 6 luni',
                  },
                ],
              },
            },
            {
              title: 'Cookie-uri de funcționalitate',
              description:
                'Cookie-urile de funcționalitate ne ajută să îți reținem preferințele pentru o experiență personalizată. De exemplu, ne permit să salvăm limba preferată, astfel încât să nu fie nevoie să o setezi de fiecare dată când vizitezi site-ul. Aceste cookie-uri asigură că site-ul funcționează conform preferințelor tale.',
              linkedCategory: CAT_FUNCTIONALITY,
              cookieTable: {
                headers: {
                  name: 'Nume',
                  domain: 'Serviciu',
                  description: 'Descriere',
                  expiration: 'Expirare',
                },
                body: [
                  {
                    name: 'auth-q',
                    domain: appDomain,
                    description: 'Folosit pentru a distinge utilizatorii și sesiunile în scop de raportare a erorilor.',
                    expiration: 'Expiră după 1 an',
                  },
                  {
                    name: 'locale',
                    domain: appDomain,
                    description: 'Localizare',
                    expiration: 'Expiră după 1 an',
                  },
                ],
              },
            },
            {
              title: 'Cookie-uri de performanță și analiză',
              description:
                'Aceste cookie-uri ne ajută să măsurăm și să îmbunătățim performanța site-ului, urmărind vizitele și sursele de trafic. Ele arată ce pagini sunt populare și cum navighează vizitatorii pe site.',
              linkedCategory: CAT_ANALYTICS,
              cookieTable: {
                headers: {
                  name: 'Nume',
                  domain: 'Serviciu',
                  description: 'Descriere',
                  expiration: 'Expirare',
                },
                body: [
                  {
                    name: '_ga',
                    domain: appParentDomain,
                    description: 'Folosit pentru a distinge utilizatorii.',
                    expiration: 'Expiră după 2 ani',
                  },
                  {
                    name: googleTagManagerId.replace(/G-/, '_ga_'),
                    domain: appParentDomain,
                    description: 'Folosit pentru a menține starea sesiunii.',
                    expiration: 'Expiră după 2 ani',
                  },
                ],
              },
            },
            {
              title: 'Cookie-uri de publicitate',
              description:
                'Cookie-urile de publicitate ne ajută să îți afișăm reclame relevante în funcție de interesele tale atunci când vizitezi alte site-uri. Aceste cookie-uri urmăresc activitatea ta de navigare pentru a face reclamele mai relevante.',
              linkedCategory: 'targeting',
            },
          ],
        },
      },
    },
  },
};

export default pluginConfig;
