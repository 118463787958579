import { hotjar } from 'react-hotjar';
import { hotjarHjid, hotjarHjsv } from '@crema/constants/AppConst';
import CookieConsent, { CookieConsentConfig, CookieValue, getCookie } from 'vanilla-cookieconsent';
import { CAT_ANALYTICS } from '../../../../../components/src/lib/AppCookieConsent/CookieConsentConfig';

const hotjarInit = function () {
  if (hotjar.initialized()) {
    // console.info('Already initialized. Skipping.');
    return;
  }

  hotjar.initialize({ id: hotjarHjid, sv: hotjarHjsv, debug: true });
  // console.log('hotjar.initialize');

  hotjar.event('start_hotjar');
};

const hotjarClearCookies = function (
  cookie: CookieValue,
  consentConfig: CookieConsentConfig,
  eraseCookies: typeof CookieConsent.eraseCookies,
) {
  // console.log('hotjarClearCookies', { cookie, consentConfig });

  consentConfig.categories[CAT_ANALYTICS]?.autoClear?.cookies.forEach((cookieItemConfig) => {
    console.log('cookieItemConfig call erase:', cookieItemConfig);

    eraseCookies(cookieItemConfig.name, cookieItemConfig.path, cookieItemConfig.domain);
  });
};

export { hotjarInit, hotjarClearCookies };
