import { parseJson } from './ObjectHelper';

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
export const readFromStorage = (
  key: string,
  defaultValue: any,
  expectType: 'json' | 'string' = 'json',
  storageType: 'session' | 'local' = 'local',
) => {
  if (storageType === 'local' && typeof localStorage === 'undefined') {
    return defaultValue;
  } else if (storageType === 'session' && typeof sessionStorage === 'undefined') {
    return defaultValue;
  }

  const jsonString =
    storageType === 'local'
      ? localStorage.getItem(key)
      : storageType === 'session'
        ? sessionStorage.getItem(key)
        : null;

  if (jsonString === null) {
    return defaultValue;
  }

  if (expectType === 'json') {
    const parsedJson = parseJson(jsonString, defaultValue);

    return parsedJson;
  } else if (expectType === 'string') {
    // return jsonString ?? defaultValue;
    if (typeof jsonString === 'string') {
      return jsonString;
    } else {
      return defaultValue;
    }
  } else {
    throw new Error('Invalid expectType');
  }
};

export const writeToStorage = (key: string, value: any, storageType: 'session' | 'local' = 'local') => {
  if (storageType === 'local' && typeof localStorage === 'undefined') {
    throw new Error('localStorage is not available');
  } else if (storageType === 'session' && typeof sessionStorage === 'undefined') {
    throw new Error('sessionStorage is not available');
  }

  if (storageType === 'local') {
    localStorage.setItem(key, value);
  } else if (storageType === 'session') {
    sessionStorage.setItem(key, value);
  }
};
