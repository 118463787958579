import React, { useEffect } from 'react';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import Script from 'next/script';
import { googleTagManagerId } from '@crema/constants/AppConst';
import { hotjar } from 'react-hotjar';

type Props = {
  [key: string]: string;
};

const AnalyticsUserProvider = ({ key }: Props) => {
  const { user, isAuthenticated } = useAuthUser();

  useEffect(() => {
    if (!user || !isAuthenticated) {
      // console.log('Skiping user identify. no user');
      return;
    }

    if (!hotjar.initialized()) {
      // console.log('Skiping user identify. hotjar not initialized');
      return;
    }

    console.log('Do user identify');
    hotjar.identify(user.id.toString(), {
      'Email verified': user.email_verified,
      'Team Id': user.team_id,
    });
  }, [user, isAuthenticated]);

  return (
    <>
      {user && user.id && (
        <Script id='google-analytics-user' strategy='afterInteractive'>
          {` const x = ${JSON.stringify(user)};
          gtag('config', '${googleTagManagerId}', {user_id: '${user.id}'}); `}
        </Script>
      )}
    </>
  );
};

export default AnalyticsUserProvider;
